/* import file for fonts */

@font-face {
  font-family: SomarSans;
  src: url(/assets/webfonts/SomarSans/SomarSans-Regular.ttf) format("truetype");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: SomarSans;
    src: url(/assets/webfonts/SomarSans/SomarSans-RegularItalic.ttf) format("truetype");
    font-display: swap;
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: SomarSans;
    src: url(/assets/webfonts/SomarSans/SomarSans-Bold.ttf) format("truetype");
    font-display: swap;
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: SomarSans;
    src: url(/assets/webfonts/SomarSans/SomarSans-BoldItalic.ttf) format("truetype");
    font-display: swap;
    font-weight: bold;
    font-style: italic;
}

body{
  font-family: SomarSans;
}

$defaultFont: SomarSans,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";